.App-component {
  background-color: #282c34;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  color: white;
}

.messageText {
  /* 正方形を作る */
  min-height: 20vh;
  min-width: 50vh;
  background: #cec585;
}

.appImage {
  max-width: 10vw;
  display: flex;
}
