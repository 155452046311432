@keyframes shootingStar {
  0% {
    transform: translate(0, -100%);
  }
  100% {
    transform: translate(-100vw, 100vh);
  }
}

.star {
  position: fixed;
  top: 0;
  left: 90%;
  width: 5px;
  height: 5px;
  background-color: #cec585;
  animation: shootingStar 2s linear;
  border-radius: 50%;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  color: white;
  position: fixed;
  font-size: calc(10px + 2vmin);
}

.App-body {
  background-color: #282c34;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  background-color: #5b9a8b;
  min-height: 10vh;
  color: white;
}

.circle1 {
  /* 正方形を作る */
  min-height: 50vh;
  min-width: 50vh;
  background: #cec585;
  animation: round_anime1 10s linear infinite;
  /* 4つの角を丸める */
  border-radius: 50%;
}

@keyframes round_anime1 {
  0% {
    border-radius: 30% 70% 60% 40% / 30% 40% 60% 70%;
  }
  25% {
    border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
  }
  50% {
    border-radius: 70% 30% 30% 70% / 50% 70% 30% 50%;
  }
  75% {
    border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
  }
  100% {
    border-radius: 30% 70% 60% 40% / 30% 40% 60% 70%;
  }
}

.circle2 {
  /* 正方形を作る */
  min-height: 55vh;
  min-width: 55vh;
  background: #445069;
  animation: round_anime2 8s linear infinite;
  /* 4つの角を丸める */
  border-radius: 50%;
}

@keyframes round_anime2 {
  0% {
    border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
  }
  25% {
    border-radius: 70% 30% 30% 70% / 50% 70% 30% 50%;
  }
  50% {
    border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
  }
  75% {
    border-radius: 70% 30% 30% 70% / 50% 70% 30% 50%;
  }
  100% {
    border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
  }
}

@keyframes rumble_anime {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, 2px) rotate(5deg);
  }
  50% {
    transform: translate(0, 0, 0);
  }
  75% {
    transform: translate(0, -2px) rotate(-5deg);
  }
  100% {
    transform: translate(0, 0);
  }
}

.rumble:hover {
  animation: rumble_anime 0.1s linear;
}

@keyframes little_rumble_anime {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, 1px) rotate(3deg);
  }
  50% {
    transform: translate(0, 0, 0);
  }
  75% {
    transform: translate(0, -1px) rotate(-3deg);
  }
  100% {
    transform: translate(0, 0);
  }
}

.littleRumble:hover {
  animation: little_rumble_anime 0.1s linear;
}
